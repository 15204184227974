$color_red: #ea1c2d;
$color_lightred: #e36b80;

@mixin media-md{
    @media screen and (min-width: 666px){
        @content;
    }
}
@mixin media-lg{
    @media screen and (min-width: 992px){
        @content;
    }
}