@import './styles/variables';
// @import './styles/fonts';

#root {
  background: $color_lightred;
  background-image: url(assets/background.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center top;
  min-height: 100vh;
  animation: smoke-haze 5s infinite alternate;
  // transition: background-position 2s;

  // &.thinking {
  //   background-position: right top;
  // }

  @include media-md {
    animation: unset;
    background-size: cover;
    background-position: top center;
  }
}
@keyframes smoke-haze {
  from {
    background-size: auto 100%;
  }
  to { 
    background-size: auto 110%;
  }
}
.App {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  @include media-lg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
  }

  img { max-width: 100%; }

  @import './styles/typography';

  input[type=text] {
    font-weight: 100;
    padding-left: 1rem;
    background-image: url(assets/Search_Icon.png);
    background-repeat: no-repeat;
    background-size: auto 60%;
    background-position: 95% 50%;
  }
  .button {
    font-weight: 600;
    color: #fff;
    background-color: #000;
    text-decoration: none;
    z-index: 20;
    &:before, &:after {
      content: '';
      width: .6em;
      height: .6em;
      background-image: url(assets/Star.png);
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      position: relative;
      margin: 0 .5em;
    }
  }

  @import './styles/header';

  .center-stack {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
  }
  .vert-stack {
    display: flex;
    flex-direction: column;
    padding: calc(8vh + 30vw) 2rem 2rem;
    max-width: 375px;
    margin: 0 auto;

    
    h1 { line-height: 1; }
    h1.welcome-h1 {
      margin-bottom: .5rem;
    }
    h1, p, input {
      margin-top: 0;
      margin-bottom: .8rem;
      @include media-lg { 
        margin-bottom: 1.5rem;
      }
    }
    @include media-md { 
      padding-top: calc(8vh + 120px);
    }
    @include media-lg {
      padding-top: 0;
    }

    &.promo, &.thankyou, &.comeback {
      position: relative;
      min-height: calc(92vh - calc(2rem + 30vw));
      .button#redeem {
        margin-bottom: 50vw;
      }
      
      @include media-md {
        min-height: calc(92vh - calc(2rem + 120px));
        width: 100%;

        .button#redeem, .boxed.error {
          margin-bottom: 200px;
        }
        
        &:after {
          content:'';
          background-color: #e5e5e4;
          width: 100vw;
          bottom: 0;
          position: absolute;
          height: 80.5px;
          left: calc(calc(187.5px + 2rem) - 50vw);
        }
      }
    }
  }

  img.qr {
    width: 135px;
    height: 135px;
    margin: 1rem auto;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }
  div.social {
    position: absolute;
    top: 2rem;
    right: 2rem;
    text-align: right;
    color: #000;
    z-index: 99999;
    span {
      display: block;
      
      a {
        font-size: .9em;
        font-weight: 200;
        color: #000;
        display: inline-block;
        margin-left: .5em;
        margin-right: .5em;
      }
    }
    ul {
      justify-content: flex-end;
      margin-bottom: 0;
      li .icon { fill: #000; transition: transform .3s; }
      li a:hover .icon {
        transform: scale(1.1);
      }
    }
  }

  .ac_div { width: 100%; }

  .results {
    h4 {
      font-size: 1.8em;
      margin-top: -2vh;
      margin-bottom: .8rem;
      letter-spacing: .05em;
      &:before, &:after {
        content: '';
        width: .35em;
        height: .5em;
        background-image: url(assets/Star.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        position: relative;
        margin: 0 .5em;
        filter: invert(1);
      }
    }
  }
  .highness {
    mix-blend-mode: screen;
  }
  .boxed {
    // border-radius: 5px;
    // border: 1px solid #fff;
    position: relative;
    padding-top: 1rem; padding-bottom: 1rem;
    margin-bottom: 1rem;

    * { z-index:1; position: relative; }
    h2, h3 { margin: 0 auto; }
    h2 {
      font-size: 2em;
      letter-spacing: .05em;
    }
    h2:after {
      content: '';
      display: block;
      width: .4em;
      height: .4em;
      background-image: url(assets/Star.png);
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      margin: .2rem auto .5rem;
      z-index: 1;
    }
    h3 {
      letter-spacing: .1em;
      font-size: 1em;
      span { display: block; font-size: .6em; }
    }
    &:before, &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 5px;
      z-index: 0;
    }
    &:before {
      top: 0;
      left: 0;
      border: 1px solid #fff;
      z-index: 1;
    }
    &:after {
      left: 5px;
      top: 5px;
      background: rgba(0,0,0,.6);
    }

    &.error {
      h1 { font-weight: 800; font-size: 2em; }
      padding: 4rem 2rem;
      p { font-size: 1.2em; }
      h2 {
        font-weight:500;
        line-height:1em;
        margin-bottom: .8rem;
        &:after { display: none; }
        a { color: #fff; }
      }
    }

    &.promo {
      &:before { border-color: #000; }
      padding: 1.5rem .8rem;
      &:after { background: #fff; }

      img {
        margin-bottom: -8px;
        margin-left: 5px;
        max-width: calc(100% - 5px);
      }
    }

  }
  .nearest {
    p {
      letter-spacing: .1em;
      font-weight: 300;
      &.address {
        margin-top: -.8rem;
        font-weight: 400;
      }
    }
  }
  .share {
    p {
      letter-spacing: .1em;
    }
    ul {
      display: flex;
      padding: 0;
      margin-top: -.2rem;
      margin-bottom: .8rem;
      justify-content: center;
      li {
        list-style: none;
        padding: 0 .3rem;
        position: relative;
        .icon { fill: #fff; }

        &:before { content:''; opacity:0; top: 0; transition: top .5s, opacity .5s; }

        &.copied:before {
          content: 'Link copied!';
          position: absolute;
          background: rgba(255,255,255,.8);
          color: #000;
          font-weight: 100;
          font-size: .8em;
          line-height: 1em;
          padding: .3rem;
          left: 50%;
          top: -25px;
          width: 80px;
          margin-left: -40px;
          opacity: 1;
        }
        &.downloaded:before {
          content: 'Image downloaded! share to instagram from your downloads folder!';
          position: absolute;
          background: rgba(255,255,255,.8);
          color: #000;
          font-weight: 100;
          font-size: .8em;
          line-height: 1em;
          padding: .3rem;
          left: 50%;
          top: -38px;
          width: 180px;
          margin-left: -90px;
          opacity: 1;
        }
      }
    }
  }
  p.legal {
    margin-top: 1.5rem;
    font-weight: 400;
  }
  
  .icon {
    width: 2em;
    height: 2em;
  }

  .comeback-stack {
    text-align: center;
    margin-bottom: 200px;
    h2 {
      line-height: .75em;
      font-weight: 500;
      margin: 0;
    }
    h2:first-child {
      font-size: 169px;
    }
    h2:nth-child(2) {
      font-size: 186px;
    }
    h2:last-child {
      font-size: 123px;
    }
  }

  .sammyfooter {
    max-width: none;
    width: 100vw;

    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 10;

    background-image: url(assets/9_ItalianNightClub_F_Wide_flat2.png);
    height: 50vw;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    p {
      margin-top: 35vw;
      color: #000;
      font-size: .6em;
      font-weight: 200;
      text-transform: none;
      line-height: 1.8em;
      a {
        font-size: 1.2em;
        text-transform: uppercase;
        color: #000;
        display: inline-block;
        margin-left: .5em;
        margin-right: .5em;
      }
    }

    @include media-md {
      height: 200px;
      background-size: contain;
      margin-left: calc(calc(187.5px + 2rem) - 50vw);
      p {
        margin-top: 140px;
      }
    }
  }

  .inputerror {
    margin-top: -.7rem;
    margin-bottom: .8rem;
    display: block;
  }

  // @import './styles/animation';
  .smoke {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(assets/smoke.jpg);
    opacity: .7;
    background-size: 100% auto;
    mix-blend-mode: screen;
    animation: smoke-screen 15s linear infinite;
  }
  @keyframes smoke-screen {
    from{background-position-y: 100vh;}
    to{background-position-y: 0vh;}
  }
  .dot1 {
    animation: dot-fade .5s linear infinite alternate;
  }
  .dot2 {
    animation: dot-fade .5s linear infinite alternate;
    animation-delay: .1s;
  }
  .dot3 {
    animation: dot-fade .5s linear infinite alternate;
    animation-delay: .2s;
  }
  @keyframes dot-fade {
    from{opacity: 0;}
    to{opacity:1;}
  }
}

.dt {
  display: none;
  @include media-lg {
    display: block;
  }
}
.mb {
  display: block;
  @include media-lg {
    display: none;
  }
}