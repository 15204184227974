h1, button, div, p, input {
    font-family: 'Knockout', sans-serif;
  }

  h1 {
    font-weight: 600;
  }
  h2 {
    font-weight: 500;
  }
  h3 {
    font-weight: 700;
  }
  h4 {
    font-weight: 500;
  }

  p {
    font-size: 1em;
    font-weight: 200;
  }

  input, .button {
    font-size: 1.7em;
    line-height: 2.0em;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
  }